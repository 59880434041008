.Site {
  flex-direction: row;
  display: flex;
  color: rgb(40,40,40);
}

.page-editor {
  margin: 40px;
  margin-top: 10px;
  background-color: rgb(255,255,255);
  border-top: 1px solid rgb(200,200,200);
  border-left: 1px solid rgb(200,200,200);
  border-radius: 5px;
  color: rgba(0,0,0,0.9);
  box-shadow: 3px 3px 10px rgba(0,0,0,0.4);
}

.editor-row {
  display: flex;
  flex-direction: row;
  padding: 2px;
}

.button-editor {
  padding: 10px;
  
}

#player {
  width: 185px;
}

.latest-audio-title {
  font-size: 13px;
}

.latest-audio-container {
  padding-top: 20px;
  text-align: center;
  width: 200px;
}

.property-key {
  flex: 1;
  padding-right: 20px;
  border-bottom: 0px solid rgb(200,200,200);
  padding-top: 0px;
  text-transform: capitalize;
  font-weight: 700;
  color: rgb(40,40,40);
}

.property-value {
  flex: 8;
  text-transform: capitalize;
  color: rgb(71,71,71);
}

.nested {
  flex-direction: column;
  padding: 30px;
}

.nested-1 {
  
}

.nested-0 {
  background-color: rgb(245,245,245);
}

.nest {
  padding-left: 15%;
}

.navigator {
  padding-top: 0px;
}

.controls {
  flex: 1;
  width: 200px;
  background-color: rgb(255,255,255);
  border-right: 1px solid rgb(220,220,220);
  height: 100%;
  overflow: scroll;
  position: fixed;
  left: 0px;
  top: 0px;
  box-shadow: 4px 3px 10px rgba(0,0,0,0.2);
}

.disabled----controls-container {
  overflow-y: scroll;
  max-height: 100vh;
  
}

.editor-container {
  z-index: 1;
  margin-left: 200px;
  width: calc(100% - 200px);
  padding-bottom: 60px;
}

.folder-row {
  display: flex;
  flex-direction: row;
}

.folder-name {
  display: flex;
  flex-direction: row;
}

.folder-filter-btn {
  padding-left: 5px;
}

.addpage-btn-2 {
  padding: 0px;
  background-color: rgb(245,245,245);
  border-top: 1px solid rgb(220,220,220);
}

.deletepage-btn {
  background-color: rgb(245,245,245);
  border-bottom: 1px solid rgb(220,220,220);
}

.save-btn {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  border-bottom: 0px solid rgb(220,220,220);
}

.page-title {
  color: rgb(168,61,45);
  font-size: 20px;
  font-weight: bold;
  flex: 4;
  word-break: break-word;
  background-color: rgba(255,255,255,0);
  border: 0px;
}

.file-upload {
  padding: 1px;
  margin-left: 5px;
  background-color: white;
  color: black;
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,0.2);
  font-size: 11px;
}

.translate {
  border-bottom: 1px solid rgb(220,220,220);
}

.action-btn {
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.pages-list {
  border-top: 1px solid rgba(0,0,0,0.2);
  border-bottom: 1px solid rgba(0,0,0,0);
  padding-top: 5px;
  padding-bottom: 5px;
  height: 60px;
  overflow: hidden;
  overflow-y: scroll;
  background-color: rgba(240,240,240,1);
}

.pages-folders {
  
  margin-bottom: 5px;
  padding-top: 20px;
  padding-bottom: 5px;
  padding-left: 20px;
  height: 75vh;
}

.loading-description {
  color: rgba(0,0,0,0.7);
  padding-top: 20px;
}

.loading-container {
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.Page-editor-content {
  height: 500px;
}

.show-hide-pages-icon {
  padding-left: 7px;
  margin-top: -2px;
}

.page-actions-container {
  padding: 15px;
  padding-top: 5px;
  position: fixed;
  bottom: 0px;
  left: 200px;
  right: 0px;
  background-color: rgb(255,255,255);
  box-shadow: 4px -3px 10px rgba(0,0,0,0.2);
  z-index: 300;
  overflow: hidden;
  overflow-y: scroll;
}

.navigator-header {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0,0,0,0.3);
  display: flex;
  align-items: center;
}

.hide {
  display: none;
}

.pages-list-2 {
  border-top: 1px solid rgba(0,0,0,0.4);
  border-bottom: 1px solid rgba(0,0,0,0.4);
  padding-left: 15px;
  height: 20vh;
  overflow: hidden;
  overflow-y: scroll;
  color: rgb(116,24,33);
  background-color: rgba(255,255,255,0.1);
}

.create-page-btn {
  position: fixed;
  bottom: 60px;
  right: 10px;
  z-index: 100;
}

@media only screen and (min-width: 1200px) {
  .page-actions-container {
    position: fixed;
    padding-top: 0px;
    top: 0px;
    right: 0px;
    left: 70%;
    background-color: rgb(255,255,255);
    box-shadow: -4px 3px 10px rgba(0,0,0,0.2);
    z-index: 300;
    flex-direction: column;
  }
  .editor-container {
    width: 58%;
  }
  .navigator-header {
    display: none;
  }
  .Page-editor-content {
    height: 100vh;
  }
  .pages-list-2 {
    height: 50vh;
  }
  .pages-list {
    height: 50vh;
  }
  .hide {
    display: flex;
    flex-direction: column;
  }
  .create-page-btn {
    bottom: 30px;
    right: 32%;
  }
}

.external-link {
  color: blue;
}

.small-icon {
  font-size: 22px;
  padding-right: 20px;
}

.Dropdown-root {
  z-index: 0;
}

.green {
  color: green;
}

.red {
  color: red;
}

.light-dark {
  color: rgb(6,110,126);
}

.zero-padding {
  padding: 0px;
}

.username-area {
  padding-left: 45px;
  padding-right: 35px;
  margin-top: -13px;
  border-bottom: 0px solid rgb(220,220,220);
  padding-bottom: 15px;
}

.Site-btn {
  background-color: rgb(245,245,245);
  border-top: 1px solid rgb(220,220,220);
  margin-bottom: 0px;
}

.Hosts-btn {
  border-top: 1px solid rgb(220,220,220);
  background-color: rgb(245,245,245);
  margin-bottom: 0px;
}

.logout-btn {
  background-color: rgb(245,245,245);
  border-bottom: 1px solid rgb(220,220,220);
}

.language-btn {
  border-bottom: 0px solid rgb(220,220,220);
}

.tiny-icon {
  position: absolute;
  right: 5px;
  margin-top: -1px;
  font-size: 14px;
  padding-right: 10px;
  color: rgb(146,146,146);
}

.tiny-icon-2 {
  margin-top: -2px;
  font-size: 16px;
  padding-left: 2px;

}

.page-tools {
  padding: 5px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.navigator-title {
  padding: 20px;
  font-size: 12px;
  font-weight: bold;
}

.search-input {
  background-color: rgba(255,255,255,0);
  border: 0px;
  color: rgba(0,0,0,0.9);
  width: 90%;
  padding: 7px;
  padding-left: 0px;
  margin-left: 4px;
  outline-width: 0;
  margin-bottom: 4px;
}

.search-input::placeholder {
  color: rgba(20,20,20,0.6);
  font-size: 11px;
}

.list-item-0 {
  padding: 2px;
  padding-left: 5px;
  background-color: rgba(255,255,255,0.3);
  border-bottom: 1px solid rgba(180,170,170,1);
}

.list-item-1 {
  padding: 2px;
  padding-left: 5px;
  border-bottom: 1px solid rgba(180,170,170,1);
}

.add-page-icon {
  font-size: 70px;
  color: rgba(90,130,90,1);
}

.addpage-modal {
  min-width: 80vw;
}

.addpage-title {
  padding: 10px;
  margin-bottom: 25px;  
  font-size: 18px;
  border-bottom: 1px solid rgba(0,0,0,0.2);
}

.addpage-btn {
  padding: 20px;
  text-align: right;
  color: green;
  font-weight: bold;
  font-size: 13px;
}

.pagename-title {
  padding-left: 10px;
  padding-bottom: 10px;
  font-weight: bold;
}

.addpage-input {
  width: 92%;
  padding: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.newlanguage-modal {

}

.newlanguage-modal-title {
  padding: 10px;
  margin-bottom: 25px;  
  font-size: 18px;
  border-bottom: 1px solid rgba(0,0,0,0.2);
}

.select-base-lang-desc {
  padding: 10px;
  padding-top: 0px;
  font-size: 12px;
  color: rgba(0,0,0,0.7);
}

.origintarget-title {
  padding: 10px;
  padding-top: 25px;
  font-weight: bold;
}

.select-languages-container {
  text-align: center;
}

.create-language-btn {
  padding: 10px;
  text-align: right;
  color: green;
  font-weight: bold;
  font-size: 13px;
}

.newpage-open-modal {
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgb(245,245,245);
  border-top: 1px solid rgb(220,220,220);
  display: flex;
  align-items: center;
  font-weight: bold;
}

.drawer-item {
  justify-content: center;
  font-weight: 400;
  border-bottom: 1px solid rgb(220,220,220);
}

.delete-language-btn {
  padding: 20px;
  padding-bottom: 10px;
  text-align: right;
  color: red;
  font-weight: bold;
  font-size: 13px;
}

.deletepage-container {
 min-width: 80vw;
}

.site-btn {
  padding: 15px;
  background-color: rgb(245,245,245);
  align-items: center;
  display: flex;

}

.publish-btn {
  border-top: 1px solid rgb(220,220,220);
}