.Login {
  text-align: center;
}

#username-input {
  font-size: 14px;
  background-color: white;
  overflow: hidden;
  border-radius: 3px;
}

#password-input {
  font-size: 14px;
  background-color: white;
  overflow: hidden;
  border-radius: 3px;
}

.login-btn {
  padding: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}